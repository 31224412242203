// extracted by mini-css-extract-plugin
export var ArtistDescription = "PeterSaul-module--ArtistDescription--IUfS3";
export var ButtonWrapper = "PeterSaul-module--ButtonWrapper --Vdpqt";
export var CardWrapper = "PeterSaul-module--CardWrapper--gs51D";
export var CarrouselWrapper = "PeterSaul-module--CarrouselWrapper--jj0-n";
export var CarrouselWrapper2 = "PeterSaul-module--CarrouselWrapper2--Y5bOd";
export var DescriptionWrapper = "PeterSaul-module--DescriptionWrapper--zVF2T";
export var ExpoWrapper = "PeterSaul-module--ExpoWrapper--4gvXa";
export var LinkWrapper = "PeterSaul-module--LinkWrapper--KchXV";
export var NameWrapper = "PeterSaul-module--NameWrapper--Z9O2O";
export var PdpWrapper = "PeterSaul-module--PdpWrapper--FElNF";
export var PhotosWrapper = "PeterSaul-module--PhotosWrapper--W0PGC";
export var ProfilWrapper = "PeterSaul-module--ProfilWrapper--TKqvo";
export var TitleWrapper = "PeterSaul-module--TitleWrapper--GLCyj";
export var VideoWrapper = "PeterSaul-module--VideoWrapper--XCnd7";
export var VideosWrapper = "PeterSaul-module--VideosWrapper--DsxWJ";
export var WorkWrapper = "PeterSaul-module--WorkWrapper--+8ZFd";
export var WorksWrapper = "PeterSaul-module--WorksWrapper--jJO-E";
export var Wrapper = "PeterSaul-module--Wrapper--GuriX";